import React, { createContext, useContext, useState, useEffect } from 'react';

const ReturnFlightContext = createContext();

export const ReturnFlightProvider = ({ children }) => {
  const [returnFlight, setReturnFlight] = useState(() => {
    const storedReturnFlight = localStorage.getItem('returnFlights');
    return storedReturnFlight ? JSON.parse(storedReturnFlight) : null;
  });

  useEffect(() => {
    if (returnFlight) {
      localStorage.setItem('returnFlights', JSON.stringify(returnFlight));
    }
  }, [returnFlight]);

  const updateReturnFlight = (newFlight) => {
    setReturnFlight(newFlight); 
  };

  const clearReturnFlight = () => {
    setReturnFlight(null);
    localStorage.removeItem('returnFlights');
  };

  return (
    <ReturnFlightContext.Provider value={{ returnFlight, updateReturnFlight, clearReturnFlight }}>
      {children}
    </ReturnFlightContext.Provider>
  );
};

export const useReturnFlight = () => {
  return useContext(ReturnFlightContext);
};
