import React, { createContext, useContext, useState, useEffect } from 'react';

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [search, setSearch] = useState(() => {
    const storedSearch = localStorage.getItem('mySearch');
    return storedSearch ? JSON.parse(storedSearch) : null;
  });

  useEffect(() => {
    if (search) {
      localStorage.setItem('mySearch', JSON.stringify(search));
    }
  }, [search]);

  const updateSearch = (newSearch) => {
    setSearch(newSearch); 
  };

  const clearSearch = () => {
    setSearch(null);
    localStorage.removeItem('mySearch');
  };

  return (
    <SearchContext.Provider value={{ search, updateSearch, clearSearch }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  return useContext(SearchContext);
};
