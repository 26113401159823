import React from "react";

function Footer() {
  return (
    <footer className="hayaanw6G7">
      <div className="hayaanw6G7-social-links">
        <div className="hayaanc0H07">
          <div className="hayaanc0H07-social">
            <div className="hayaanHNGi">
              <a
                type="link"
                href="https://www.facebook.com/hayaan/"
                target="_blank"
                rel="noreferrer"
                className="hayaanPy0r hayaanPy0r-mod-variant-interactive hayaanPy0r-mod-theme-base hayaanPy0r-mod-shape-default hayaanPy0r-mod-size-medium"
                tabIndex={0}
                aria-disabled="false"
                title="Follow on Facebook"
                aria-label="Follow on Facebook"
              >
                <div className="hayaanPy0r-button-container">
                  <div className="hayaanPy0r-button-content">
                    <div className="hayaanfMzx-icon-wrapper">
                      <svg
                        viewBox="0 0 200 200"
                        width="1.25em"
                        height="1.25em"
                        xmlns="http://www.w3.org/2000/svg"
                        className="hayaanfMzx-icon"
                        type="presentation"
                      >
                        <path d="M180 100c0-44.179-35.821-80-80-80s-80 35.821-80 80c0 39.927 29.25 73.025 67.501 79.033V123.13H67.183V100h20.318V82.371c0-20.048 11.948-31.129 30.218-31.129c8.753 0 17.91 1.564 17.91 1.564v19.688h-10.092c-9.934 0-13.038 6.165-13.038 12.499V100h22.185l-3.543 23.13h-18.642v55.902C150.75 173.036 180 139.938 180 100z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </a>
              <a
                type="link"
                href="https://twitter.com/HAYAAN"
                target="_blank"
                rel="noreferrer"
                className="hayaanPy0r hayaanPy0r-mod-variant-interactive hayaanPy0r-mod-theme-base hayaanPy0r-mod-shape-default hayaanPy0r-mod-size-medium"
                tabIndex={0}
                aria-disabled="false"
                title="Follow on Twitter"
                aria-label="Follow on Twitter"
              >
                <div className="hayaanPy0r-button-container">
                  <div className="hayaanPy0r-button-content">
                    <div className="hayaanfMzx-icon-wrapper">
                      <svg
                        viewBox="0 0 200 200"
                        width="1.25em"
                        height="1.25em"
                        xmlns="http://www.w3.org/2000/svg"
                        className="hayaanfMzx-icon"
                        type="presentation"
                      >
                        <path d="M113.96 88.51L168.6 25h-12.95l-47.44 55.15L70.32 25H26.61l57.3 83.4l-57.3 66.6h12.95l50.1-58.24L129.68 175h43.71l-59.43-86.49h0zm-17.74 20.61l-5.81-8.3l-46.18-66.07h19.89l37.28 53.33l5.81 8.3l48.46 69.32h-19.89l-39.54-56.56h0z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </a>
              <a
                type="link"
                href="https://www.youtube.com/channel/UCs36_o9B5NadGYfiHwT-ekQ"
                target="_blank"
                rel="noreferrer"
                className="hayaanPy0r hayaanPy0r-mod-variant-interactive hayaanPy0r-mod-theme-base hayaanPy0r-mod-shape-default hayaanPy0r-mod-size-medium"
                tabIndex={0}
                aria-disabled="false"
                title="Subscribe on YouTube"
                aria-label="Subscribe on YouTube"
              >
                <div className="hayaanPy0r-button-container">
                  <div className="hayaanPy0r-button-content">
                    <div className="hayaanfMzx-icon-wrapper">
                      <svg
                        viewBox="0 0 200 200"
                        width="1.25em"
                        height="1.25em"
                        xmlns="http://www.w3.org/2000/svg"
                        className="hayaanfMzx-icon"
                        type="presentation"
                      >
                        <path d="M176.66 61.49c-1.84-6.89-7.27-12.31-14.15-14.15C150.03 44 100 44 100 44s-50.03 0-62.51 3.34c-6.88 1.84-12.31 7.26-14.15 14.15C20 73.97 20 100 20 100s0 26.03 3.34 38.51c1.84 6.89 7.27 12.31 14.15 14.15C49.97 156 100 156 100 156s50.03 0 62.51-3.34c6.88-1.84 12.31-7.26 14.15-14.15C180 126.03 180 100 180 100s0-26.03-3.34-38.51zM84 124V76l41.57 24L84 124z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </a>
              <a
                type="link"
                href="https://www.instagram.com/hayaan/"
                target="_blank"
                rel="noreferrer"
                className="hayaanPy0r hayaanPy0r-mod-variant-interactive hayaanPy0r-mod-theme-base hayaanPy0r-mod-shape-default hayaanPy0r-mod-size-medium"
                tabIndex={0}
                aria-disabled="false"
                title="Follow on Instagram"
                aria-label="Follow on Instagram"
              >
                <div className="hayaanPy0r-button-container">
                  <div className="hayaanPy0r-button-content">
                    <div className="hayaanfMzx-icon-wrapper">
                      <svg
                        viewBox="0 0 200 200"
                        width="1.25em"
                        height="1.25em"
                        xmlns="http://www.w3.org/2000/svg"
                        className="hayaanfMzx-icon"
                        type="presentation"
                      >
                        <path d="M132.313 34.909c21.483.98 31.797 11.276 32.778 32.778c.668 14.642.668 49.977 0 64.625c-.98 21.483-11.276 31.797-32.778 32.778c-14.64.668-49.979.668-64.625 0c-21.483-.98-31.797-11.276-32.778-32.778c-.668-14.642-.668-49.977 0-64.625c.98-21.483 11.276-31.797 32.778-32.778c14.641-.668 49.976-.668 64.625 0zM67.031 20.516C38.273 21.828 21.85 37.812 20.516 67.031c-.688 15.09-.688 50.854 0 65.939c1.312 28.751 17.288 45.181 46.514 46.514c15.086.688 50.856.688 65.939 0c28.751-1.312 45.181-17.288 46.514-46.514c.688-15.09.688-50.854 0-65.939c-1.312-28.758-17.296-45.181-46.514-46.514c-15.089-.689-50.854-.689-65.938-.001zM100 58.937c-22.678 0-41.063 18.385-41.063 41.063S77.322 141.063 100 141.063s41.063-18.385 41.063-41.063c0-22.679-18.385-41.063-41.063-41.063zm0 67.718c-14.721 0-26.655-11.934-26.655-26.655c0-14.721 11.934-26.655 26.655-26.655c14.721 0 26.655 11.934 26.655 26.655c0 14.721-11.934 26.655-26.655 26.655z" />
                        <circle cx="142.685" cy="57.315" r="9.596" />
                      </svg>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="hayaanc0H07-privacy">
            <div className="hayaanO4Ux">
              <span className="hayaanO4Ux-year-brand">©2024 HAYAAN</span>
            </div>
          </div>
        </div>
      </div>
      <div className="hayaanw6G7-footer-logo-wrapper">
        <div className="hayaanjy7a">
          <div className="hayaanjy7a-brands">
            <div className="r60B">
              <img src="/res/img/hayanlogo.png" className="w-[240px] mx-auto" alt="" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
