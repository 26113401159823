import React, { createContext, useContext, useState, useEffect } from 'react';

const FlightContext = createContext();

export const FlightProvider = ({ children }) => {
  const [flight, setFlight] = useState(() => {
    const storedFlight = localStorage.getItem('airports');
    return storedFlight ? JSON.parse(storedFlight) : null;
  });

  useEffect(() => {
    if (flight) {
      localStorage.setItem('airports', JSON.stringify(flight));
    }
  }, [flight]);

  const updateFlight = (newFlight) => {
    setFlight(newFlight); 
  };

  const clearFlight = () => {
    setFlight(null);
    localStorage.removeItem('airports');
  };

  return (
    <FlightContext.Provider value={{ flight, updateFlight, clearFlight }}>
      {children}
    </FlightContext.Provider>
  );
};

export const useFlight = () => {
  return useContext(FlightContext);
};
