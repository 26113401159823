import React, { useState } from 'react';
import {
  FaHome,
  FaUsers,
  FaChevronDown,
  FaChevronRight,
  FaPlusCircle,
  FaDollarSign,
  FaUserShield,
  FaGlobe,
  FaPen,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useUser } from '../../context/UserContext';

function MenuItem({ item, isNested = false }) {
  const [isOpen, setIsOpen] = useState(false);
  
  const handleClick = (e) => {
    if (item.subItems) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className={`${isNested ? 'ml-6' : ''}`}>
      <Link
        to={item.path}
        onClick={handleClick}
        className="flex items-center gap-4 px-4 py-2 mb-2 text-gray-300 rounded hover:bg-gray-700 hover:text-white transition-all duration-200 cursor-pointer"
      >
        <span className="w-5">{item.icon}</span>
        <span className="text-sm font-medium flex-1">{item.name}</span>
        {item.subItems && (
          <span className="text-xs">
            {isOpen ? <FaChevronDown /> : <FaChevronRight />}
          </span>
        )}
      </Link>

      {item.subItems && isOpen && (
        <div className="ml-4 transition-all duration-200">
          {item.subItems.map((subItem, index) => (
            <MenuItem key={index} item={subItem} isNested={true} />
          ))}
        </div>
      )}
    </div>
  );
}

function Sidebar() {
  const { user } = useUser();
  
  const menuItems = [
      {
        name: 'Profile',
        icon: <FaUsers />,
        path: '/profile',
        subItems: [
          { 
            name: 'Change Password', 
            icon: <FaPen />, 
            path: '/change-password' 
          },
          ...(user.isPasswordChanged && user.role === 'AGENT' 
            ? [
                { name: 'View Profile', icon: <FaUsers />, path: `/user/${user.userId}` },
                { name: 'Currency List', icon: <FaGlobe />, path: '/currencies' },
                { name: 'Conversions', icon: <FaGlobe />, path: '/currency-conversions' },
              ] 
            : []
          ),
          ...(user.isPasswordChanged && user.role !== 'AGENT' 
            ? [
                { name: 'View Profile', icon: <FaUsers />, path: `/user/${user.userId}` },
              ] 
            : []
          ),
        ],
      },
      
    ...(user.isPasswordChanged && user.role !== 'AGENT' ? [
      { name: 'Dashboard', icon: <FaHome />, path: '/dashboard' },
      {
        name: 'Users',
        icon: <FaUsers />,
        path: '/users',
        subItems: [
          { name: 'User List', icon: <FaUsers />, path: '/users' },
          { name: 'Create User', icon: <FaPlusCircle />, path: '/create-user' },
        ],
      },
      {
        name: 'Agents',
        icon: <FaUserShield />,
        path: '/agents',
        subItems: [
          { name: 'Agent List', icon: <FaUsers />, path: '/agents' },
          { name: 'Agent Types', icon: <FaUsers />, path: '/agent-types' },
          { name: 'Create Agent Type', icon: <FaPlusCircle />, path: '/create-agent-type' },
          { name: 'Create Agent', icon: <FaPlusCircle />, path: '/create-agent' },
        ],
      },
      {
        name: 'Commission',
        icon: <FaDollarSign />,
        path: '/commissions',
        subItems: [
          { name: 'Commission List', icon: <FaDollarSign />, path: '/commissions' },
          { name: 'By User', icon: <FaUsers />, path: '/commission-by-user' },
          { name: 'Create Type', icon: <FaPlusCircle />, path: '/create-commission-type' },
        ],
      },
      {
        name: 'Currency',
        icon: <FaGlobe />,
        path: '/currencies',
        subItems: [
          { name: 'Currency List', icon: <FaGlobe />, path: '/currencies' },
          { name: 'Create Currency', icon: <FaPlusCircle />, path: '/create-currency' },
          { name: 'Conversions', icon: <FaGlobe />, path: '/currency-conversions' },
          { name: 'Create Conversion', icon: <FaPlusCircle />, path: '/create-currency-conversion' },
          { name: 'Update Conversion', icon: <FaPen />, path: '/update-currency-conversion' },
        ],
      },
      {
        name: 'Roles',
        icon: <FaUserShield />,
        path: '/roles',
        subItems: [
          { name: 'Role List', icon: <FaUserShield />, path: '/roles' },
          { name: 'Create Role', icon: <FaPlusCircle />, path: '/create-role' },
        ],
      }
    ] : [])
  ];

  return (
    <div className="h-screen overflow-y-auto bg-gray-800 text-white min-w-64 p-4 flex flex-col z-50">
      <nav className="flex-1 mt-20 text-left">
        {menuItems.map((item, index) => (
          <MenuItem key={index} item={item} />
        ))}
      </nav>
      <div className="mt-auto">
        <p className="text-center text-sm text-gray-500">
          © 2024 Hayaan. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}

export default Sidebar;