import React, { createContext, useContext, useState, useEffect } from 'react';

const OnwardFlightContext = createContext();

export const OnwardFlightProvider = ({ children }) => {
  const [onwardFlight, setOnwardFlight] = useState(() => {
    const storedOnwardFlight = localStorage.getItem('onwardFlights');
    return storedOnwardFlight ? JSON.parse(storedOnwardFlight) : null;
  });

  useEffect(() => {
    if (onwardFlight) {
      localStorage.setItem('onwardFlights', JSON.stringify(onwardFlight));
    }
  }, [onwardFlight]);

  const updateOnwardFlight = (newFlight) => {
    setOnwardFlight(newFlight); 
  };

  const clearOnwardFlight = () => {
    setOnwardFlight(null);
    localStorage.removeItem('onwardFlights');
  };

  return (
    <OnwardFlightContext.Provider value={{ onwardFlight, updateOnwardFlight, clearOnwardFlight }}>
      {children}
    </OnwardFlightContext.Provider>
  );
};

export const useOnwardFlight = () => {
  return useContext(OnwardFlightContext);
};
